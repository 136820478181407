import React, {Component} from "react";

import AuthService from "./services/AuthService";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
//import {validEmail} from "../utils/TypeUtils";

const sucessMsg = "You have successfully Signed Up!\nPls contact admin to access application";

export default class Signup extends Component {
    constructor(props) {
        super(props);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
        this.handleSignup = this.handleSignup.bind(this);

        this.state = {
            username: "",
            email: "",
            password: "",
            confirmPassword: "",
            message: "",
            loading: false,
        };
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }

    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    onChangeConfirmPassword(e) {
        this.setState({
            confirmPassword: e.target.value
        });
    }

    handleSignup(e) {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true
        });

        if (this.validateInputs()) {
            AuthService.register(this.state.username, this.state.email, this.state.password).then(
                () => {
                    this.setState({
                        loading: false,
                        message: sucessMsg
                    });
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        loading: false,
                        message: resMessage
                    });
                }
            );
        }
    }

    validateInputs = () => {
        if (this.state.username === "") {
            this.setState({
                message: "Username is required!",
                loading: false,
            });
            return false;
        }

        if (this.state.email === "") {
            this.setState({
                message: "Email is required!",
                loading: false,
            });
            return false;
        }
        // if (!validEmail(this.state.email)) {
        //     this.setState({
        //         message: "Email is invalid!",
        //         loading: false,
        //     });
        //     return false;
        // }

        if (this.state.password === "") {
            this.setState({
                message: "Password is required!",
                loading: false,
            });
            return false;
        }
        if (this.state.password !== this.state.confirmPassword) {
            this.setState({
                message: "Password & Confirm Password in not matching!",
                loading: false,
            });
            return false;
        }

        return true;
    };

    render() {
        return (
            <div style={{padding: 10}}>
                <div>
                    <Form.Group controlId="formBasicPassword">
                        <Row>
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text"
                                          className="form-control"
                                          name="username"
                                          value={this.state.username}
                                          onChange={this.onChangeUsername}/>
                        </Row>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Row>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email"
                                          className="form-control"
                                          name="email"
                                          value={this.state.email}
                                          onChange={this.onChangeEmail}/>
                        </Row>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Row>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password"
                                          className="form-control"
                                          name="password"
                                          value={this.state.password}
                                          onChange={this.onChangePassword}/>
                        </Row>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Row>
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password"
                                          className="form-control"
                                          name="confirmPass"
                                          value={this.state.confirmPassword}
                                          onChange={this.onChangeConfirmPassword}/>
                        </Row>
                    </Form.Group>

                    <Row>
                        <button
                            className="btn btn-success btn-block"
                            disabled={this.state.loading}
                            onClick={this.handleSignup}>
                            {this.state.loading && (
                                <span className="spinner-border spinner-border-sm"/>
                            )}
                            <span> Sign Up</span>
                        </button>
                    </Row>

                    <br/>
                    {this.state.message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {this.state.message}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
