import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles/index';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {ENTER_KEY_CODE} from "../../constants";
import {Clear} from "@material-ui/icons";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => (
    {
        bootstrapRoot: {
            padding: 0,
            'label + &': {
                marginTop: theme.spacing(1),
            },
        },
        bootstrapInput: {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            border: '1px solid #ced4da',
            padding: '5px 12px',
            marginTop: '11px',
            marginBottom: '11px',
            width: '100%',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
            fontSize: 12,
            height:'20px',
        },
    }
));

export default function InventoryPOSearch(props) {
    const classes = useStyles();
    const {onSearch, searchBtn, resetFullView, products, tableData} = props;
    const [searchState, setSearchState] = useState({});
    const [poNumber, setPoNumber] = useState('');
    const [item, setItem] = useState('');
    const [color, setColor] = useState('');
    const [location, setLocation] = useState('');
    const [availableQty, setAvailableQty] = useState('');
    const [locationACKey, setLocationACKey] = useState(Math.random().toString(36));
    const [colorACKey, setColorACKey] = useState(Math.random().toString(36));

    const handleInventorySearchChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        console.log(e.target.value);
        if (name === 'availableQty') {
            value = parseInt(value);
        }
        let newState = searchState;
        if (value === '' || value.length === 0 || (name === 'availableQty' && isNaN(value))) {
            delete newState[name];
        } else {
            newState[name] = value;
        }
        setFormValues(name, value);
        console.log(newState);
        setSearchState(newState);
    };

    function setFormValues(name, value) {
        if (name === 'poNumber') {
            setPoNumber(value);
        } else if (name === 'color') {
            setColor(value);
        } else if (name === 'item') {
            setItem(value);
        } else if (name === 'location') {
            setLocation(value);
        } else if (name === 'availableQty') {
            setAvailableQty(value);
        }
    }

    const handleReset = () => {
        setPoNumber('');
        setColor('');
        setItem('');
        setLocation('');
        setAvailableQty('');
        setLocationACKey(Math.random().toString(36));
        setColorACKey(Math.random().toString(36));
        setSearchState({});
        if (resetFullView) {
            onSearch({});
        } else {
            onSearch({poNumber:'empty'});
        }
    };

    const handleOnkeyDown = (e) => {
        if (e.keyCode === ENTER_KEY_CODE) {
            onSearch(searchState);
        }
    };

    return (
        <div>
            <Grid container spacing={1} direction="row" border={2}>
                <Grid item xs={1}/>
                <Grid item xs={2}>
                    <TextField
                        name='poNumber'
                        id='standard-full-width'
                        label={'PO #'}
                        required
                        defaultValue={''}
                        onChange={handleInventorySearchChange}
                        onKeyDown={handleOnkeyDown}
                        value={poNumber}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        name='item'
                        id='standard-full-width'
                        label={'Item'}
                        required
                        onChange={handleInventorySearchChange}
                        onKeyDown={handleOnkeyDown}
                        value={item}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        freeSolo
                        id='color'
                        key={colorACKey}
                        disableClearable
                        onChange={(event, newValue) => {
                            let newState = searchState;
                            if (newValue === '' || newValue.length === 0) {
                                delete newState['color'];
                            } else {
                                newState['color'] = newValue;
                            }
                            setFormValues('color', newValue);
                            setSearchState(newState);
                            onSearch(searchState);
                        }}
                        options={[...new Set(tableData.map((option) => option.color))]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Color"
                                name='color'
                                value={color}
                                fullWidth
                                onChange={handleInventorySearchChange}
                                onKeyDown={handleOnkeyDown}
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    disableUnderline: true,
                                    classes: {
                                        root: classes.bootstrapRoot,
                                        input: classes.bootstrapInput,
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        freeSolo
                        id='location'
                        key={locationACKey}
                        disableClearable
                        onChange={(event, newValue) => {
                            let newState = searchState;
                            if (newValue === '' || newValue.length === 0) {
                                delete newState['location'];
                            } else {
                                newState['location'] = newValue;
                            }
                            setFormValues('location', newValue);
                            setSearchState(newState);
                            onSearch(searchState);
                        }}
                        options={[...new Set(tableData.map((option) => option.location))]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Location"
                                name='location'
                                value={location}
                                fullWidth
                                onChange={handleInventorySearchChange}
                                onKeyDown={handleOnkeyDown}
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    disableUnderline: true,
                                    classes: {
                                        root: classes.bootstrapRoot,
                                        input: classes.bootstrapInput,
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    {searchBtn && <Button onClick={onSearch} color="primary">Search</Button>}
                    <Button onClick={handleReset} variant="outlined" disableElevation size="small"
                            startIcon={<Clear/>}
                            style={{marginTop: '19px'}}>
                        Reset
                    </Button>
                </Grid>
                <Grid item xs={1}/>
            </Grid>
        </div>
    );
}

InventoryPOSearch.propTypes = {
    onSearch: PropTypes.func,
    searchBtn: PropTypes.array,
    resetFullView: PropTypes.object,
    products: PropTypes.array,
    tableData: PropTypes.array
};

InventoryPOSearch.defaultProps = {
    onSearch: () => {
    },
    searchBtn: false,
    resetFullView: false,
    products: [],
    tableData: []
};
