import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Route, Switch} from 'react-router';
import './App.css';
import LoginPage from './user/Login';
import SecuredRouter from './user/SecuredRouter';

function App() {
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path='/login' component={LoginPage}/>
                <Route component={SecuredRouter} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
