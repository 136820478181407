 import ResourceAPIs from '../../apis/UserAPI';

class AuthService {

    login(username, password) {
        return new ResourceAPIs().login(username, password).then(response => {
            if (response.data.Authorization) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
    }

    loginErrorReason(username) {
        return new ResourceAPIs().loginErrorReason(username).then(response => {
            return response.data;
        });
    }

    logout() {
        localStorage.removeItem('user');
        window.location.href = "/login";
    }

    register(username, email, password) {
        return new ResourceAPIs().register(username, email, password);
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    getCurrentUserRole() {
        return localStorage.getItem('role');
    }

    userRole(username) {
        return new ResourceAPIs().userRole(username).then(response => {
            if (response.data) {
                localStorage.setItem("role", response.data);
            }
            return response.data;
        });
    }
}

export default new AuthService();
