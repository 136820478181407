import axios from 'axios';
import {AuthorizationHeader} from "../user/services/AuthHeader";
import {HOST_NAME} from "../constants";
import AuthService from "../user/services/AuthService";

export default class UserAPI {

    getHTTPClient(isLogin) {
        let httpClient = axios.create({
            baseURL: HOST_NAME,
            headers: AuthorizationHeader()
        });

        httpClient.defaults.headers.post['Content-Type'] = 'application/json';

        httpClient.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response) {
                if (isLogin === undefined) {
                    if (error.response.status === 403) {
                        localStorage.removeItem('user');
                        window.location.href = "/";
                    }
                }
            }
            return Promise.reject(error);
        });

        return httpClient;
    }

    // Users

    getUsers() {
        return this.getHTTPClient().get('/users');
    }

    updateUser(id, role) {
        return this.getHTTPClient().post("/users/update-role?id=" + id + "&role=" + role);
    }

    deleteUser(id) {
    return this.getHTTPClient().delete(`/users?id=${id}`);
    }

    getClientId() {
        return this.getHTTPClient().post(HOST_NAME + "/users/client-id",
            {username: AuthService.getCurrentUser().User});
    }

    // Authentication

    login(username, password) {
        return this.getHTTPClient(true).post("/login", {username, password});
    }

    loginErrorReason(username) {
        return this.getHTTPClient().post("/users/error-reason", {username});
    }

    register(username, email, password) {
        return this.getHTTPClient().post("/users/sign-up", {
            username,
            email,
            password
        });
    }

    userRole(username) {
        return this.getHTTPClient().post("/users/role", {
            username
        });
    }
}
