import React, { useState, useEffect } from 'react';
import Parent from '../components/Parent';
import MUIDataTable from "mui-datatables";
import CustomToolbar from '../components/custom/CustomToolbar';
import Api from "../apis/Api";
import InventorySelector from '../components/common/InventorySelector';
import { useParams } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DeleteDialog from '../components/common/ConfirmationDialog';
import IconButton from "@material-ui/core/IconButton";
import {ArrowForward, Delete, PostAdd} from "@material-ui/icons";
import {handleError} from "../utils/MiscellaniousUtils";
import {Alert} from "@material-ui/lab";
import POReport from "../components/print/po-report/POReport";
import CustomerHandler from '../components/common/CustomerSelector';

export default function PODetailsPage() {

    const { id } = useParams();
    const api = new Api();
    const [order, setOrder] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [poItems, setPoItems] = useState([]);
    const [invSelectorOpen, setinvSelectorOpen] = useState(false);
    const [customerSelectorOpen, setCustomerSelectorOpen] = useState(false);
    const [itemDeleteDialogOpen, setItemDeleteDialogOpen] = useState(false);
    const [focussedItem, setFocussedItem] = useState(null);

    useEffect(() => {
        // retrieve order details
        getOrderDetails();
        // retrieve order details for id and set to poItems
        retrievePullOrderInventory();
    },[]);

    function getOrderDetails() {
        api.getPullOrderDetails(id).then((response) => {
            // console.log(response);
            setOrder(response.data);
            // get customer
            getCustomerDetails(response.data.clientId);
        }).catch((error) => {
            handleError(error);
        });
    }

    function getCustomerDetails(id) {
        api.getCustomer(id).then((response) => {
            // console.log(response);
            setCustomer(response.data)
        }).catch((error) => {
            handleError(error);
        });
    }

    function retrievePullOrderInventory() {
        api.getPullOrderInventory(id).then((response) => {
            let list = response.data;
            let rows = [];
            list.forEach((element) => {
                const rowData = [];
                rowData.push(element.id);
                rowData.push(element.poNumber);
                rowData.push(element.item);
                rowData.push(element.description);
                rowData.push(element.color);
                rowData.push(element.location);
                rowData.push(element.qty);
                rows.push(rowData);
            });
            setPoItems(rows);
        }).catch((error) => {

        });
    }

    const handleCreatePO = () => {
        api.setOrderAsPulled(id).then((response) => {
            getOrderDetails();
        }).catch((error) => {
            handleError(error);
        });
    };

    const handleInvSelectorOpen = () => {
        setinvSelectorOpen(true);
    };

    const handleInvSelectorClose = () => {
        setinvSelectorOpen(false);
    };

    const handleCustomerSelectorOpen = () => {
        setCustomerSelectorOpen(true);
    };

    const handleCustomerSelectorClose = () => {
        setCustomerSelectorOpen(false);
    };

    const handleItemDelete = () => {
        api.deleteInventoryFromOrder(focussedItem).
        then((response) => {
            handleDeleteItemDialogClose();
            retrievePullOrderInventory();
        }).catch((error) => {
            handleError(error);
        });
    };

    const handleDeleteItemDialogClose = () => {
        setItemDeleteDialogOpen(false);
    };

    const handleRevertOrder = () => {
        api.setOrderAsReverted(id).then((response) => {
            getOrderDetails();
        }).catch((error) => {
            handleError(error);
        });
    };

    function renderCustomToolBar(order) {
        if (order) {
            switch (order.state) {
                case 'pulled':
                    return (
                        <>
                            <POReport poId={id} poItems={poItems} customer={customer}/>
                            <CustomToolbar handleClick={handleRevertOrder} buttonText={'Revert'}/>
                        </>
                    );
                default:
                    return (
                        <>
                            <POReport poId={id} poItems={poItems} customer={customer}/>
                            <CustomToolbar handleClick={handleCreatePO} buttonText={'Create PO'}
                                           startIcon={<ArrowForward/>}/>
                            <CustomToolbar handleClick={handleInvSelectorOpen} buttonText={'Add More'}
                                           startIcon={<PostAdd/>}/>
                        </>
                    );
            }
        }
    }

    function isEditable(order) {
        if (order) {
            return order.state === 'new' || order.state === 'reverted';
        }
    }

    const columns = [
        {
            name: 'id',
            options: {
                display: false,
            }
        },
        "PO #", "Item", "Item Description", "Color", "Location", "Quantity",
        {
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <IconButton variant="contained" color="secondary" disableElevation onClick={() => {
                                setFocussedItem(tableMeta.rowData[0]);
                                setItemDeleteDialogOpen(true);
                            }}>
                                <Delete/>
                            </IconButton>
                        </>
                    )
                },
                display: isEditable(order),
            }
        },];
    const options = {
        filterType: 'checkbox',
        filter: false,
        print: false,
        viewColumns: false,
        responsive: 'standard',
        search: false,
        elevation: 0,
        selectableRows: false,
        customToolbar: () => {return (renderCustomToolBar(order));},
    };

    return(
        <Parent title={`Pull Order ID: ${id}`}>
            {order && order.state === 'pulled' && (
                <Alert severity="info" style={{marginBottom: 20}}>PO REMOVED FROM INVENTORY!</Alert>
            )}
            <p>Customer Name : {customer && customer.fname && customer.lname &&  customer.fname + " " + customer.lname}</p>
            <MUIDataTable
                title={"Item Sheet"}
                data={poItems}
                columns={columns}
                options={options}
            />
            <Dialog
                open={customerSelectorOpen}
                onClose={handleCustomerSelectorClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle id="alert-dialog-title">{"Add Item"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <CustomerHandler orderId={id}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCustomerSelectorClose} color="primary">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={invSelectorOpen}
                onClose={handleInvSelectorClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle id="alert-dialog-title">{"Add Item"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <InventorySelector orderId={id} onComplete={retrievePullOrderInventory}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleInvSelectorClose} color="primary">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
            <DeleteDialog open={itemDeleteDialogOpen}
                          title={'Delete Item'}
                          contentText={'Are you sure you want to delete this item?'}
                          onConfirm={handleItemDelete}
                          onClose={handleDeleteItemDialogClose}/>
        </Parent>
    );
}
