import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {InsertDriveFile} from "@material-ui/icons";

const defaultToolbarStyles = {
    iconButton: {},
};

class CustomToolbarImport extends React.Component {

    handleClick = () => {
        this.props.handleClick();
    };

    onChangeFile = (e) => {
        this.props.onChangeFile(e);
    };

    render() {
        const {classes, buttonText} = this.props;

        return (
            <React.Fragment>
                <div style={{float: 'left', paddingBottom: '20px', marginLeft: '30px'}}>
                    <Grid container>
                        <form className={classes.root} noValidate autoComplete="off">
                            <TextField id="standard-basic" type="file" name="file" size="small"
                                       onChange={this.onChangeFile}
                                       key={this.props.selectedFileInputKey}
                                       helperText={this.props.selectedFileError}
                                       disabled={this.props.isProcessing}
                            />
                        </form>
                        <Button variant="contained" onClick={this.handleClick}
                                style={{marginLeft: 10}}
                                startIcon={<InsertDriveFile/>} disableElevation>
                            {buttonText}
                        </Button>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }

}

export default withStyles(defaultToolbarStyles, {name: "CustomToolbarImport"})(CustomToolbarImport);