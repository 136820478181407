import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from "@material-ui/core/styles/index";

const useStyles = makeStyles((theme) => (
    {
        progress: {
            width: '64px',
            height: '64px',
            position: 'absolute',
            left: '50%',
            top: '50%',
        },
    }
));

export default function Progress(props) {

    const classes = useStyles();

    return (<div className={classes.progress}><CircularProgress/></div>);
}
