import React, {useState, useEffect, useReducer} from 'react';
import Parent from '../components/Parent';
import MUIDataTable from "mui-datatables";
import Link from '@material-ui/core/Link';
import {useHistory} from "react-router";
import CustomToolbar from '../components/custom/CustomToolbar';
import ResourceAPI from '../apis/Api';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles/index';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import {alertError} from "../utils/MiscellaniousUtils";
import ResourceApi from '../apis/Api';
import {format2NiceDate} from "../utils/DateUtils";
import IconButton from "@material-ui/core/IconButton";
import {Delete} from "@material-ui/icons";
import ConfirmationDialog from "../components/common/ConfirmationDialog";
import {handleError} from "../utils/MiscellaniousUtils";


function reducer(state, { field, value }) {
    return { ...state, [field]: value };
}

const useStyles = makeStyles((theme) => (
    {
        bootstrapRoot: {
            padding: 0,
            'label + &': {
                marginTop: theme.spacing(1),
            },
        },
        bootstrapInput: {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            border: '1px solid #ced4da',
            padding: '5px 12px',
            marginTop: '11px',
            marginBottom: '11px',
            width: '100%',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
            fontSize: 12,
        },
    }
));

export default function POListingPage(props) {

    const history = useHistory();
    const classes = useStyles();
    const [poList, setPoList] = useState([]);
    const [insertCustomerDialogOpen, setInsertCustomerDialogOpen] = useState(false);
    const [initialState, setInitialState] = useState({
        fname: '',
        lname: '',
        cnumber: '',
        address: '',
    });
    const [state, dispatch] = useReducer(reducer, initialState);

    const [focussedItem, setFocussedItem] = useState([]);
    const [deleteItemDialogOpen, setDeleteItemDialogOpen] = useState(false);
    useEffect(() => {
        // retrieve all saved pull orders
        retrievePOList();
    }, []);

    function retrievePOList() {
        const api = new ResourceAPI();
        api.getAllPullOrders().then((response) => {
            console.log(response.data);
            let list = response.data;
            let rows = [];
            list.forEach((element) => {
                const rowData = [];
                rowData.push(element.id);
                rowData.push(element.clientName);
                if(element.state === "pulled"){
                    rowData.push(
                        <Chip
                            label="Removed from Inventory"
                            color="primary"
                            variant="outlined"
                        />
                    )
                }else{
                    rowData.push("")
                }
                rowData.push(format2NiceDate(element.createdAt));
                rows.push(rowData);
            });
            setPoList(rows);
        }).catch((error) => {
            handleError(error);
        });
    }

    const handleCreateEmptyPO = (customerId) => {
        new ResourceAPI().createEmptyOrder(customerId).then((response) => {
            retrievePOList();
            history.push(`/po/details/${response.data}`)
        }).catch((error) => {
            handleError(error);
        });
    };

    const handleInsertCustomerOpen = () => {
        setInsertCustomerDialogOpen(true);
    };

    const handleInsertCustomerClose = () => {
        setInsertCustomerDialogOpen(false);
    };

    const handleCustomerInsert = () => {
        new ResourceAPI().createCustomer(state).then((response) => {
            handleCreateEmptyPO(response.data.id);
        }).catch((error) => {
            alertError(error);
        });
    };

    const handleChange = (e) => {
        dispatch({ field: e.target.name, value: e.target.value });
    };

    const handleDeleteItemDialogClose = () => {
        setDeleteItemDialogOpen(false);
    };

    const handleItemDelete = () => {
        const api = new ResourceApi();
        api.deletePullOrder(focussedItem[0]).then((response) => {
            handleDeleteItemDialogClose();
            retrievePOList();
        }).catch((error) => {
            handleDeleteItemDialogClose();
            handleError(error);
        });
    };

    const columns = [{
        label: "Order Id",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <Link component="button" variant="body2" onClick={() => {
                        history.push(`/po/details/${tableMeta.rowData[0]}`)
                    }}>
                        {tableMeta.rowData[0]}
                    </Link>
                )
            }
        }
    },
        "Customer",
        "Labels",
        "Created Date",
        {
            label: "Controls",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <IconButton variant="contained" style={{marginLeft: '5px'}} color="secondary"
                                        disableElevation
                                        onClick={() => {
                                            setFocussedItem(tableMeta.rowData);
                                            setDeleteItemDialogOpen(true);
                                        }}>
                                <Delete/>
                            </IconButton>
                        </>
                    )
                }
            }
        },
    ];

    const options = {
        filterType: 'checkbox',
        filter: false,
        print: false,
        viewColumns: false,
        selectableRows: false,
        download: false,
        elevation: 0,
        customToolbar: () => {return (<CustomToolbar handleClick={handleInsertCustomerOpen} buttonText={'Create New Order'} />);},
    };

    return (
        <Parent title='PO List'>
            <MUIDataTable
                title={"PO Listing Sheet"}
                data={poList}
                columns={columns}
                options={options}
            />
            <Dialog open={insertCustomerDialogOpen} onClose={handleInsertCustomerClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="alert-dialog-title">{"Customer Information!"}</DialogTitle>
                <DialogContent>
                    <TextField
                        name='fname'
                        id='standard-full-width'
                        label={'First Name'}
                        required
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='lname'
                        id='standard-full-width'
                        label={'Last Name'}
                        required
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='cnumber'
                        id='standard-full-width'
                        label={'Contact Number'}
                        required
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='address'
                        id='standard-full-width'
                        label={'Address'}
                        required
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleInsertCustomerClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCustomerInsert} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog open={deleteItemDialogOpen}
                                title={'Delete Item'}
                                contentText={'Are you sure you want to delete this PO?'}
                                onConfirm={handleItemDelete}
                                onClose={handleDeleteItemDialogClose}/>
        </Parent>);
}
