import React, {useContext} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import UsersIcon from '@material-ui/icons/SupervisedUserCircle';
import BallotIcon from '@material-ui/icons/Ballot';
import ListIcon from '@material-ui/icons/List';
import {useHistory} from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import {Link} from "react-router-dom";
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import Chip from '@material-ui/core/Chip';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SearchIcon from '@material-ui/icons/Search';
import AuthService from "../../user/services/AuthService";
import UserContext from '../../context/UserContext';
import {ADMIN, SALES, WAREHOUSE} from "../../constants";
import PropTypes from "prop-types";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        maxHeight: '64px',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        fontFamily: 'Adequate Bold',
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
}));

export default function Header(props) {
    const user = useContext(UserContext);
    const {hasDrawer} = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const history = useHistory();

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleLogout = () => {
        AuthService.logout();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle/>
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.grow}>
            <AppBar position="fixed" style={{backgroundColor: '#f3a302'}} className={classes.appBar}>
                <Toolbar>
                    <MenuItem>
                        <img src={"/JJCabinetLogo.png"} height="50px" alt="JJ Cabinet Logo" onClick={() => {
                            history.push(`/inventory`)
                        }}/>
                    </MenuItem>
                    <div className={classes.grow}/>
                    <div className={classes.sectionDesktop}>
                        <Chip
                            icon={<AccountCircle/>}
                            label={(user.currentUser ? user.currentUser.User : 'Anonymous') + ' [' + user.role + ']'}
                            onClick={handleProfileMenuOpen}
                            variant="outlined"
                        />
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon/>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {hasDrawer &&
            <>
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}/>
                    <Divider/>
                    <List>
                        <ListItem button component={Link} to="/inventory-lookup">
                            <ListItemIcon><ListIcon style={{color: '#f3a302'}}/></ListItemIcon>
                            <ListItemText primary="Inventory Look Up"/>
                        </ListItem>
                        <ListItem button component={Link} to="/po-list">
                            <ListItemIcon><DashboardIcon style={{color: '#f3a302'}}/></ListItemIcon>
                            <ListItemText primary="Pull Orders"/>
                        </ListItem>
                        {user.currentUser && (
                            <>
                                {user.role === SALES.value && (
                                    <>
                                    </>
                                )}
                                {user.role === ADMIN.value && (
                                <>
                                    <ListItem button component={Link} to="/inventory">
                                        <ListItemIcon><ChromeReaderModeIcon style={{color: '#f3a302'}}/></ListItemIcon>
                                        <ListItemText primary="Container Inventory"/>
                                    </ListItem>
                                    <ListItem button component={Link} to="/containers">
                                        <ListItemIcon><BallotIcon style={{color: '#f3a302'}}/></ListItemIcon>
                                        <ListItemText primary="Container Levels"/>
                                    </ListItem>
                                    <ListItem button component={Link} to="/users">
                                        <ListItemIcon><UsersIcon style={{color: '#f3a302'}}/></ListItemIcon>
                                        <ListItemText primary="Users"/>
                                    </ListItem>
                                </>
                                )}
                                {user.role === WAREHOUSE.value &&
                                <>
                                </>
                                }
                            </>
                        )}
                    </List>
                </Drawer>
                {renderMobileMenu}
                {renderMenu}
            </>
            }
        </div>
    );
}

Header.propTypes = {
    hasDrawer: PropTypes.bool,
};

Header.defaultProps = {
    hasDrawer: true
};
