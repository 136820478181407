import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";

export default function ConfirmationDialog(props) {
    let {title, contentText, onConfirm, open, onClose} = props;

    const handleOnConfirm = () => {
        onConfirm();
    };

    return(
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {contentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleOnConfirm} color="primary" autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    contentText: PropTypes.string,
    onConfirm: PropTypes.func,
    handleDeleteItemDialogClose: PropTypes.func,
};

ConfirmationDialog.defaultProps = {
    open: false,
    title: "",
    contentText: "",
    onConfirm: () => {
    },
    handleDeleteItemDialogClose: () => {
    }
};