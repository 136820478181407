import React, {Component} from "react";
import {Redirect} from 'react-router';
import AuthService from "./services/AuthService";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import RedirectUrlService from "./services/RedirectUrlService";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Signup from "./Signup";
import Box from "@material-ui/core/Box";
import LoginHeader from "../components/common/LoginHeader";
import {NO_ACCESS} from "../constants";

const loginPageStyle = {
    whiteSpace: "pre-wrap",
    backgroundImage: "url('/banner.jpg')",
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
};

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        this.state = {
            username: "",
            password: "",
            loading: false,
            message: "",
            authenticated: false,
        };
    }

    componentWillMount() {
        this.initAuthenticationFlow();
    }

    /**
     * Check if the user has already signed in and remember me is set
     */
    initAuthenticationFlow() {
        if (AuthService.getCurrentUser() && AuthService.getCurrentUserRole() !== NO_ACCESS.value) {
            this.setState({authenticated: true})
        } else {
            this.setState({authenticated: false})
        }
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    handleLogin(e) {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true
        });

        if (this.validateInputs()) {
            AuthService.login(this.state.username, this.state.password).then(
                () => {
                    // get user role
                    AuthService.userRole(this.state.username).then(
                        (res) => {
                            // check if there redirect url
                            if (RedirectUrlService.getRedirectUrl()) { // if available go to url
                                window.location.href = RedirectUrlService.getRedirectUrl();
                                RedirectUrlService.removeRedirectUrl();
                            } else { // else refresh page
                                window.location.reload();
                            }
                        },
                        error => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();

                            this.setState({
                                loading: false,
                                message: resMessage
                            });
                        }
                    )
                },
                error => {
                    AuthService.loginErrorReason(this.state.username).then(
                        (res) => {
                            this.setState({
                                loading: false,
                                message: res
                            });
                        },
                        err => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();

                            this.setState({
                                loading: false,
                                message: resMessage
                            });
                        }
                    )
                }
            );
        }
    }

    validateInputs = () => {
        if (this.state.username === "") {
            this.setState({
                message: "Username is required!",
                loading: false,
            });
            return false;
        }
        if (this.state.password === "") {
            this.setState({
                message: "Password is required!",
                loading: false,
            });
            return false;
        }
        return true;
    };

    render() {
        const authenticated = this.state.authenticated;
        if (authenticated) {
            return (
                <Redirect to='/inventory'/>
            );
        }
        return (
                <Box className='page-container' style={loginPageStyle}>
                    <LoginHeader/>
                    <div>
                        <div>
                            <br/><br/><br/>
                            <center>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="login">

                                    <Card style={{width: '24rem'}}>
                                        <Card.Body>
                                            <Nav fill variant="tabs">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="login">Login</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="signup">Sign Up</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <br/>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="login">
                                                    <Col>
                                                        <Image src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                                                               roundedCircle/>
                                                    </Col>
                                                    <Form style={{padding: 10}}>
                                                        <Form.Group controlId="formBasicPassword">
                                                            <Row>
                                                                <Form.Label>Username</Form.Label>
                                                                <Form.Control type="text"
                                                                              className="form-control"
                                                                              name="username"
                                                                              value={this.state.username}
                                                                              onChange={this.onChangeUsername}/>
                                                            </Row>
                                                        </Form.Group>

                                                        <Form.Group controlId="formBasicPassword">
                                                            <Row>
                                                                <Form.Label>Password</Form.Label>
                                                                <Form.Control type="password"
                                                                              className="form-control"
                                                                              name="password"
                                                                              value={this.state.password}
                                                                              onChange={this.onChangePassword}/>
                                                            </Row>
                                                        </Form.Group>

                                                        <br/>
                                                        <Row>
                                                            <button
                                                                className="btn btn-success btn-block"
                                                                disabled={this.state.loading}
                                                                onClick={this.handleLogin}>
                                                                {this.state.loading && (
                                                                    <span className="spinner-border spinner-border-sm"/>
                                                                )}
                                                                <span> Login</span>
                                                            </button>
                                                        </Row>

                                                        <br/>
                                                        {this.state.message && (
                                                            <div className="form-group">
                                                                <div className="alert alert-danger" role="alert">
                                                                    {this.state.message}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Form>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="signup">
                                                    <Signup/>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Card.Body>
                                    </Card>
                                </Tab.Container>
                            </center>
                        </div>
                    </div>
                </Box>
        );
    }
}
