import React, {useState, useEffect, useContext} from 'react';
import ResourceApi from "../apis/Api";
import Parent from "../components/Parent";
import Progress from '../components/common/Progress';
import UserContext from '../context/UserContext';
import ReactTable from 'react-table-6'
// using an older version (v6) of react-table since v7 supports headless approach, hence more work has to be done to render the table
import 'react-table-6/react-table.css'
import Chip from '@material-ui/core/Chip';
import {handleError} from "../utils/MiscellaniousUtils";

const CHIP_MAX_WIDTH = 100;
const CHIP_ICON_WIDTH = 30;

const EllipsisText = props => {
    const { children } = props;

    return (
        <div
            style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: CHIP_MAX_WIDTH - CHIP_ICON_WIDTH,
                minWidth:30
            }}
        >
            {children}
        </div>
    );
};

export default function SalesTeamInventoryPage(props) {

    const [products, setProducts] = useState([]);
    const user = useContext(UserContext);

    useEffect(() => {
        retrieveAllInventory();
    },[]);

    function retrieveAllInventory() {
        const api = new ResourceApi();
        api.getReorderItems().then((response) => {
            let list = response.data;
            if (list) {
                setProducts(list);
            }
        }).catch((error) => {
            handleError(error);
        });
    }

    const renderQuantity = (row) => {
        if (row.original) {
            switch (row.original.status) {
                case "red":
                    return (<Chip label={<EllipsisText>{row.row.quantity}</EllipsisText>} variant="outlined" style={{backgroundColor:'red'}}/>)
                case "yellow":
                    return (<Chip label={<EllipsisText>{row.row.quantity}</EllipsisText>} variant="outlined" style={{backgroundColor:'yellow'}}/>)
                case "green":
                    return (<span>{row.row.quantity}</span>)
            }
        }
    };

    const filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        if (id === 'quantity' && isNaN(filter.value)) {
            // special use case where user searches quantity by color
            return row[id] !== undefined ? String(row._original.status.toLowerCase()).startsWith(filter.value.toLowerCase()) : true
        }
        return row[id] !== undefined ? String(row[id].toString().toLowerCase()).startsWith(filter.value.toLowerCase()) : true
    };

    const columns = [{
        Header: props => <b>Item</b>,
        accessor: 'item'
    }, {
        Header: props => <b>Description</b>,
        accessor: 'description',
        Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
    }, {
        Header: props => <b>Color</b>,
        accessor: 'color'
    }, {
        Header: props => <b>Quantity</b>,
        accessor: 'quantity',
        //getProps: trProps,
        Cell: renderQuantity,
        maxWidth:80,
    },{
        Header: props => <b>Max Capacity</b>,
        accessor: 'maxCapacity',
        filterable: false
    }, {
        Header: props => <b>Reorder Amount</b>,
        accessor: 'reorderAmount',
        filterable: false
    },  {
        Header: props => <b>Fall Off Amount</b>,
        accessor: 'falloffAmount',
        filterable: false
    }, {
        Header: props => <b>Rules</b>,
        columns: [
            {
                Header: props => <b><p style={{color:'red'}}>Red</p></b>,
                accessor: 'red',
                filterable: false,
                maxWidth:80,
                Cell: props => <span className='number'>{props.value}%</span>
            },
            {
                Header: props => <b><p style={{color:'orange'}}>Yellow</p></b>,
                accessor: 'yellow',
                filterable: false,
                maxWidth:80,
                Cell: props => <span className='number'>{props.value}%</span>
            },
            {
                Header: props => <b><p>Reorder</p></b>,
                accessor: 'reorder',
                filterable: false,
                maxWidth:80,
                Cell: props => <span className='number'>{props.value}%</span>
            },
            {
                Header: props => <b><p>Fall Off</p></b>,
                accessor: 'falloff',
                filterable: false,
                maxWidth:80,
                Cell: props => <span className='number'>{props.value}%</span>
            }
        ],
    }
    ]

    // only admin access
    if (user && user.currentUser && !user.isAdmin) {
        return (<Progress/>);
    }

    return (
        <Parent title='Container Levels'>
            <ReactTable
                data={products}
                columns={columns}
                pageSize={100}
                filterable
                defaultFilterMethod={filterMethod}
            />
        </Parent>
    );
}
