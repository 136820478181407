export const PROD_API_URL = "https://jjit.ca/api"
export const PROD_API_UNSECURE_URL = "http://165.227.38.11:9090";
export const LOCAL_API_URL = "http://localhost:9090";
export const HOST_NAME = PROD_API_URL;

// User Roles
export const NO_ACCESS_OPTION = {value: "NO_ACCESS", label: "Access Denied"};
export const ADMIN = {value: "ADMIN", label: "Admin"};
export const CLIENT = {value: "CLIENT", label: "Client"};
export const NO_ACCESS = {value: "NO_ACCESS", label: "Unauthorized"};
export const SALES = {value: "SALES", label: "Sales"};
export const WAREHOUSE = {value: "WAREHOUSE", label: "Warehouse"};

export const userRoles = [
    NO_ACCESS_OPTION,
    ADMIN,
    CLIENT,
];

export const INTERNAL_SERVER_ERROR = "Internal server error!";
export const IMPORT_TASK = "Add Import Task";
export const SAVE = "Save";
export const IMPORT = "Import";
export const IMPORTING = "Importing...";
export const DISCONTINUE = "Discontinue";
export const DISCONTINUING = "Discontinuing...";

export const ENTER_KEY_CODE = 13;

export const MAX_FILE_SIZE = 10000000; // 10MB
export const MAX_FILE_SIZE_STRING = "10MB"; // 10MB
