import React from 'react';
import PrintComponents from "react-print-components";
import IconButton from "@material-ui/core/IconButton";
import {Print} from "@material-ui/icons";
import './POReports.css';
import {format2NiceDate} from "../../../utils/DateUtils";

export default (props) => {
    if (props.value === null || props.value === "") {
        return <br/>;
    } else {
        return (
            <PrintComponents
                trigger={
                    <IconButton aria-label="info" size="small">
                        <Print/>
                    </IconButton>
                }>
                <div style={{margin: 25}}>
                    <h3>PO Report</h3>
                    <span style={{float: 'right', marginBottom: 20}}>{format2NiceDate(new Date())}</span>
                    <br/>
                    <table width="100%">
                        <tbody>
                        <tr>
                            <td width="30%">Pull Order ID</td>
                            <td width="70%">{props.poId}</td>
                        </tr>
                        <tr>
                            <td width="30%">Customer</td>
                            <td width="70%">{getCustomerName(props.customer)}</td>
                        </tr>
                        </tbody>
                    </table>
                    <br/>
                    <table id="po-report">
                        <thead>
                        <tr>
                            <th>PO #</th>
                            <th>Item</th>
                            <th>Item Description</th>
                            <th>Color</th>
                            <th>Location</th>
                            <th>Qty</th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.poItems.map((row, index) => (
                            <tr key={index}>
                                <td>{row[1]}</td>
                                <td>{row[2]}</td>
                                <td>{row[3]}</td>
                                <td>{row[4]}</td>
                                <td>{row[5]}</td>
                                <td>{row[6]}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </PrintComponents>
        );
    }
};

function getCustomerName(customer) {
    if (customer && customer.fname && customer.lname) {
        return customer.fname + " " + customer.lname;
    } else {
        return "N/A";
    }
}

function formatTitle(title) {
    if (title !== null && title.length > 120) {
        title = title.substring(0, 119) + "..."
    }
    return title;
}
