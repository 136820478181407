import React, {useContext, useEffect, useReducer, useState} from 'react';
import Parent from '../components/Parent';
import MUIDataTable from "mui-datatables";
import ResourceApi from '../apis/Api';
import CustomToolbar from '../components/custom/CustomToolbar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles/index';
import TextField from '@material-ui/core/TextField';
import ConfirmationDialog from '../components/common/ConfirmationDialog';
import Search from '../components/common/InventorySearch';
import _ from 'lodash';
import {alertSuccess, handleError, handleFileSizeExceedsError} from "../utils/MiscellaniousUtils";
import {
    DISCONTINUE,
    DISCONTINUING,
    IMPORT,
    INTERNAL_SERVER_ERROR,
    MAX_FILE_SIZE,
    MAX_FILE_SIZE_STRING,
    WAREHOUSE
} from "../constants";
import IconButton from "@material-ui/core/IconButton";
import UserContext from '../context/UserContext';
import {Add, Delete, Edit} from "@material-ui/icons";
import {Redirect} from "react-router-dom";
import {Table} from "react-bootstrap";
import CustomToolbarImport from "../components/custom/CustomToolbarImport";
import {Alert} from "@material-ui/lab";

function reducer(state, { field, value }) {
    return { ...state, [field]: value };
}

const useStyles = makeStyles((theme) => (
    {
        bootstrapRoot: {
            padding: 0,
            'label + &': {
                marginTop: theme.spacing(1),
            },
        },
        bootstrapInput: {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            border: '1px solid #ced4da',
            padding: '5px 12px',
            marginTop: '11px',
            marginBottom: '11px',
            width: '100%',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
            fontSize: 12,
        },
    }
));

export default function InventoryPage(props) {

    const user = useContext(UserContext);
    const classes = useStyles();
    const [products, setProducts] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [focussedItem, setFocussedItem] = useState([]);
    const [deleteItemDialogOpen, setDeleteItemDialogOpen] = useState(false);
    const [inventoryInsertDialogOpen, setInventoryInsertDialogOpen] = useState(false);
    const [inventoryUpdateDialogOpen, setInventoryUpdateDialogOpen] = useState(false);
    const [pODetailInsertDialogOpen, setPODetailInsertDialogOpen] = useState(false);
    const [initialState, setInitialState] = useState({
        inventoryId: null,
        item: '',
        description: '',
        color: '',
        reorder: null,
        yellow: null,
        red: null,
        falloff: null,
        maxCapacity: null,
    });
    const [state, dispatch] = useReducer(reducer, initialState);
    const [editState, setEditState] = useState({
        inventoryId: null,
        item: '',
        description: '',
        color: '',
        reorder: null,
        yellow: null,
        red: null,
        falloff: null,
        maxCapacity: null,
    });
    const [initialPODetailState, setInitialPODetailState] = useState({
        poNumber: '',
        itemId: null,
        qty: null,
        locationDetails: []
    });
    const [initialLocationDetail, setInitialLocationDetail] = useState({
        location: '',
        qty: 0,
    });
    const [locDetailCount, setLocDetailCount] = useState([]);
    const [statePODetail, dispatchPODetail] = useReducer(reducer, initialPODetailState);
    const [selectedFile, setSelectedFile] = useState(null);
    const[selectedFileError, setSelectedFileError] = useState('');
    const [selectedFileInputKey, setSelectedFileInputKey] = useState(Math.random().toString(36));
    const [importBtnText, setImportBtnText] = useState(IMPORT);
    const [isProcessing, setIsProcessing] = useState(false);
    const [mergeDiagnostics, setMergeDiagnostics] = useState('');

    useEffect(() => {
        retrieveAllInventory();
    },[]);

    function retrieveAllInventory() {
        const api = new ResourceApi();
        api.getAllInventory().then((response) => {
            let list = response.data;
            if (list) {
                setProducts(list);
            }
            setTableData(list);
        }).catch((error) => {
            handleError(error);
        });
    }

    function createRows(list) {
        let rows = [];
        list.forEach((element) => {
            const rowData = [];
            rowData.push(element.inventoryId);
            rowData.push(element.item);
            rowData.push(element.description);
            rowData.push(element.color);
            rowData.push(element.reorder);
            rowData.push(element.yellow);
            rowData.push(element.red);
            rowData.push(element.falloff);
            rowData.push(element.maxCapacity);
            rows.push(rowData);
        });
        return rows;
    }

    function mergeShouldRender() {
        return user && user.currentUser && user.isAdmin
    }

    const handleInsertInventoryOpen = () => {
        setInventoryInsertDialogOpen(true);
    };

    const handleInsertInventoryClose = () => {
        setInventoryInsertDialogOpen(false);
    };

    const handleUpdateInventoryClose = () => {
        setInventoryUpdateDialogOpen(false);
    };

    const handleChange = (e) => {
        console.log(e.target.name);
        dispatch({ field: e.target.name, value: e.target.value });
    };

    const handleInsertPODetailOpen = (id) => {
        handlePODetailItemIdChange(id)
        setPODetailInsertDialogOpen(true);
    };

    const handleInsertPODetailClose = () => {
        setPODetailInsertDialogOpen(false);
        handlePODetailItemIdChange(null);
        dispatchPODetail({ field: 'poNumber', value: '' });
        dispatchPODetail({ field: 'locationDetails', value: [] });
    };

    const handlePODetailChange = (e) => {
        dispatchPODetail({ field: e.target.name, value: e.target.value });
    };

    const handlePODetailItemIdChange = (id) => {
        dispatchPODetail({ field: 'itemId', value: id });
    };

    const handlePODetailLocationChange = (e) => {
        setInitialLocationDetail({
            ...initialLocationDetail,
            location: e.target.value
        })
    };

    const handlePODetailQtyChange = (e) => {
        setInitialLocationDetail({
            ...initialLocationDetail,
            qty: e.target.value
        })
    };

    const handleAddPODetailLocation = () => {
        if(initialLocationDetail.location !== '' && initialLocationDetail.qty !== 0){
            const arr = [...statePODetail.locationDetails]
            arr.push(initialLocationDetail)
            setInitialLocationDetail({
                location: '',
                qty: 0,
            })
            dispatchPODetail({ field: 'locationDetails', value: arr });
        }
    };

    const handleSearch = (searchState) => {
        const filteredItems = _.filter(products, function (o) {
            return _.every(searchState, function (v, k) {
                return _.startsWith(o[k].toLowerCase(), v.toLowerCase());
            });
        });
        setTableData(filteredItems);
    };

    const handleEditFormChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let newState = editState;
        newState[name] = value;
        setEditState(newState);
    };

    const handleInventoryInsert = () => {
        const api = new ResourceApi();
        console.log(state);
        api.insertInventoryItem(state).then((response) => {
            handleInsertInventoryClose();
            retrieveAllInventory();
        }).catch((error) => {
            handleError(error);
        });
    };

    const handleInventoryUpdate = () => {
        new ResourceApi().updateInventoryItem(editState).then((response) => {
            handleUpdateInventoryClose();
            retrieveAllInventory();
        }).catch((error) => {
            handleError(error);
        });
    };

    const handlePODetailInsert = () => {
        console.log(statePODetail)
        const api = new ResourceApi();
        api.insertPODetail(statePODetail).then((response) => {
            handleInsertPODetailClose();
            retrieveAllInventory();
        }).catch((error) => {
            handleInsertPODetailClose();
            handleError(error);
        });
    };

    const handleDeleteItemDialogClose = () => {
        setDeleteItemDialogOpen(false);
    };

    const handleItemDelete = () => {
        const api = new ResourceApi();
        api.deleteInventoryItem(focussedItem[0]).then((response) => {
            handleDeleteItemDialogClose();
            retrieveAllInventory();
        }).catch((error) => {
            handleError(error);
        });
    };

    const handleSelectInventoryDiscontinueFile = (e) => {
        if (e.target.files[0] !== undefined) {
            if (e.target.files[0].size > MAX_FILE_SIZE) {
                handleFileSizeExceedsError(MAX_FILE_SIZE_STRING);
                setSelectedFile(null);
            } else {
                setSelectedFile(e.target.files[0]);
            }
        }
    };

    const validateInputs = () => {
        if (selectedFile == null) {
            setSelectedFileError('Pls select the file you want to import!');
            return false;
        } else {
            setSelectedFileError('');
        }
        return true;
    };

    const clearFrom = () => {
        setSelectedFile(null);
        setSelectedFileInputKey(Math.random().toString(36));
    };

    const handleDiscontinueBulkInventory = () => {
        const data = new FormData();
        data.append('file', selectedFile);

        if (validateInputs()) {
            setImportBtnText(DISCONTINUING);
            setIsProcessing(true);

            const api = new ResourceApi();
            api.discontinueInvStock(data)
                .then(res => {
                    setImportBtnText(DISCONTINUE);
                    setIsProcessing(false);
                    alertSuccess(res.data);
                    clearFrom();
                    retrieveAllInventory();
                })
                .catch(error => {
                    setImportBtnText(DISCONTINUE);
                    setIsProcessing(false);
                    let err = INTERNAL_SERVER_ERROR;
                    if (error.response) {
                        err = error.response.data;
                        if (typeof err !== "string") {
                            console.log(err);

                            let errMsg = "[" + err.error + "]<br/><b>" + err.message + "</b><br/><br/>";
                            if (err.trace) {
                                let ks = err.trace.split("\n");
                                errMsg += "\n" + ks[0];
                            }
                            err = errMsg;
                        }
                    }
                    setMergeDiagnostics(err);
                });
        }
    };

    const columns = [
        {
            'name': 'inventoryId',
            'label': 'ID',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'item',
            'label': 'Item',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'desc',
            'label': 'Item Description',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'color',
            'label': 'Color',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'reorder',
            'label': 'Re-Order',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'yellow',
            'label': 'Yellow',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'red',
            'label': 'Red',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'falloff',
            'label': 'Fall Off',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'maxCapacity',
            'label': 'Max Capacity',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'actions',
            'label': 'Actions',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <CustomToolbar handleClick={() => handleInsertPODetailOpen(tableMeta.rowData[0])} buttonText={'Add PO Detail'} startIcon={<Add/>}/>
                            <IconButton variant="contained" disableElevation onClick={() => {
                                //setFocussedItem(tableMeta.rowData);
                                setEditState({
                                    inventoryId: tableMeta.rowData[0],
                                    item: tableMeta.rowData[1],
                                    description: tableMeta.rowData[2],
                                    color: tableMeta.rowData[3],
                                    reorder: tableMeta.rowData[4],
                                    yellow: tableMeta.rowData[5],
                                    red: tableMeta.rowData[6],
                                    falloff: tableMeta.rowData[7],
                                    maxCapacity: tableMeta.rowData[8],
                                });
                                setInventoryUpdateDialogOpen(true);
                            }}>
                                <Edit/>
                            </IconButton>
                            <IconButton variant="contained" style={{marginLeft:'5px'}} color="secondary"
                                        disableElevation
                                        onClick={() => {
                                setFocussedItem(tableMeta.rowData);
                                setDeleteItemDialogOpen(true);
                            }}>
                                <Delete/>
                            </IconButton>
                        </>
                    )
                }
            }
        },
    ];

    const options = {
        filterType: 'checkbox',
        customToolbar: () => {
            return (
                <>
                    <CustomToolbar handleClick={handleInsertInventoryOpen} buttonText={'Add Item'} startIcon={<Add/>}/>
                </>);
        },
        filter: false,
        print: false,
        disableToolbarSelect: true,
        search: false,
        elevation: 0,
        selectableRows: false
    };

    // only warehouse cannot access this
    if (user && user.currentUser && user.role === WAREHOUSE.value) {
        return (<Redirect to="/po-list" />);
    }

    return (
        <Parent title='Inventory Listing'>
            {/*{mergeShouldRender() && (*/}
            {/*    <>*/}
            {/*        <CustomToolbarImport onChangeFile={handleSelectInventoryDiscontinueFile}*/}
            {/*                             handleClick={handleDiscontinueBulkInventory}*/}
            {/*                             buttonText={'Discontinue'}/>*/}
            {/*        <br/><br/><br/>*/}
            {/*        {mergeDiagnostics && mergeDiagnostics !== '' && (*/}
            {/*            <Alert severity="error" style={{marginBottom: 20}}>*/}
            {/*                <span dangerouslySetInnerHTML={{ __html: mergeDiagnostics }} />*/}
            {/*            </Alert>*/}
            {/*        )}*/}
            {/*    </>*/}
            {/*)}*/}
            <Search onSearch={handleSearch} searchBtn={false} resetFullView={true} products={products} tableData={tableData}/>
            <MUIDataTable
                data={createRows(tableData)}
                columns={columns}
                options={options}
            />
            <Dialog open={inventoryInsertDialogOpen} onClose={handleInsertInventoryClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="alert-dialog-title">{"Add Inventory Item"}</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        name='item'
                        id='standard-full-width'
                        label={'Item'}
                        required
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='description'
                        id='standard-full-width'
                        label={'Item Description'}
                        required
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='color'
                        id='standard-full-width'
                        label={'Color'}
                        required
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='maxCapacity'
                        id='standard-full-width'
                        label={'Max Capacity'}
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='reorder'
                        id='standard-full-width'
                        label={'Re-order %'}
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='yellow'
                        id='standard-full-width'
                        label={'Yellow %'}
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='red'
                        id='standard-full-width'
                        label={'Red %'}
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='falloff'
                        id='standard-full-width'
                        label={'Fall Off %'}
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleInsertInventoryClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleInventoryInsert} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={inventoryUpdateDialogOpen} onClose={handleUpdateInventoryClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="alert-dialog-title">{"Update Inventory"}</DialogTitle>
                <DialogContent>
                    <TextField
                        name='item'
                        id='standard-full-width'
                        label={'Item'}
                        required
                        defaultValue={editState.item}
                        onChange={handleEditFormChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='description'
                        id='standard-full-width'
                        label={'Item Description'}
                        required
                        defaultValue={editState.description}
                        onChange={handleEditFormChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='color'
                        id='standard-full-width'
                        label={'Color'}
                        required
                        defaultValue={editState.color}
                        onChange={handleEditFormChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='maxCapacity'
                        id='standard-full-width'
                        label={'Max Capacity'}
                        onChange={handleEditFormChange}
                        defaultValue={editState.maxCapacity}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='reorder'
                        id='standard-full-width'
                        label={'Re-order %'}
                        onChange={handleEditFormChange}
                        defaultValue={editState.reorder}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='yellow'
                        id='standard-full-width'
                        label={'Yellow %'}
                        onChange={handleEditFormChange}
                        defaultValue={editState.yellow}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='red'
                        id='standard-full-width'
                        label={'Red %'}
                        onChange={handleEditFormChange}
                        defaultValue={editState.red}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='falloff'
                        id='standard-full-width'
                        label={'Fall Off %'}
                        onChange={handleEditFormChange}
                        fullWidth
                        defaultValue={editState.falloff}
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateInventoryClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleInventoryUpdate} color="primary" autoFocus>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={pODetailInsertDialogOpen} onClose={handleInsertPODetailClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="alert-dialog-title">{"Add PO Detail"}</DialogTitle>
                <DialogContent>
                    <TextField
                        name='itemId'
                        id='standard-full-width'
                        label={'Item ID'}
                        value={statePODetail.itemId}
                        disabled={true}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='poNumber'
                        id='standard-full-width'
                        label={'PO #'}
                        required
                        onChange={handlePODetailChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Table>
                        <tbody>
                        <tr>
                            <td>
                                <TextField
                                    name='location'
                                    id='standard-full-width'
                                    label={'Location'}
                                    required
                                    onChange={handlePODetailLocationChange}
                                    value={initialLocationDetail.location}
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            root: classes.bootstrapRoot,
                                            input: classes.bootstrapInput,
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </td>
                            <td>
                                <TextField
                                    name='qty'
                                    id='standard-full-width'
                                    label={'Quantity'}
                                    required
                                    onChange={handlePODetailQtyChange}
                                    value={initialLocationDetail.qty}
                                    type={'number'}
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            root: classes.bootstrapRoot,
                                            input: classes.bootstrapInput,
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </td>
                            <td>
                                <IconButton variant="contained" disableElevation onClick={handleAddPODetailLocation}>
                                    <Add/>
                                </IconButton>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                    <Table>
                        <thead>
                        <tr>
                            <th>Location</th>
                            <th>Quantity</th>
                        </tr>
                        </thead>
                        <tbody>
                        {statePODetail.locationDetails.map(item => {
                            return (
                                <tr>
                                    <td>
                                        {item.location}
                                    </td>
                                    <td>
                                        {item.qty}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleInsertPODetailClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handlePODetailInsert} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog open={deleteItemDialogOpen}
                          title={'Delete Item'}
                          contentText={'Are you sure you want to delete this item?'}
                          onConfirm={handleItemDelete}
                          onClose={handleDeleteItemDialogClose}/>
        </Parent>
    );
}
