import React, { useState, useEffect, useContext } from 'react';
import Parent from '../components/Parent';
import UserContext from '../context/UserContext';
import UserApi from '../apis/UserAPI';
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Progress from '../components/common/Progress';
import IconButton from "@material-ui/core/IconButton";
import {Add, Delete, Edit} from "@material-ui/icons";
import ConfirmationDialog from "../components/common/ConfirmationDialog";
import {alertError, handleError} from '../utils/MiscellaniousUtils';

export default function UsersPage(props) {
    const user = useContext(UserContext);
    const [userList, setUserList] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [focussedItem, setFocussedItem] = useState([]);
    const [updateRoleDialogOpen, setUpdateRoleDialogOpen] = useState(false);
    const [deleteItemDialogOpen, setDeleteItemDialogOpen] = useState(false);
    const usersApi = new UserApi();

    useEffect(() => {
        retrieveAllUsers();
    },[]);

    function retrieveAllUsers() {
        usersApi.getUsers().then((response) => {
            let list = response.data;
            console.log(response.data);
            setUserList(response.data);
            setTableData(createRows(list));
        }).catch((error) => {
            handleError(error);
        });
    }

    function createRows(list) {
        let rows = [];
        list.forEach((element) => {
            const rowData = [];
            rowData.push(element.id);
            rowData.push(element.username);
            rowData.push(element.email);
            rowData.push(element.type);
            rows.push(rowData);
        });
        return rows;
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleUpdateUserRoleDialogClose = () => {
        setUpdateRoleDialogOpen(false);
    };

    const handleUserRoleUpdate = () => {
        // call backend api and update the role with updated focused item
        usersApi.updateUser(focussedItem[0], focussedItem[3]).then((response) => {
            retrieveAllUsers();
            setUpdateRoleDialogOpen(false);
        }).catch((error) => {
            handleError(error);
        });
    };

    const handleDeleteItemDialogClose = () => {
        setDeleteItemDialogOpen(false);
    };

    const handleItemDelete = () => {
        usersApi.deleteUser(focussedItem[0]).then((response) => {
            handleDeleteItemDialogClose();
            retrieveAllUsers();
        }).catch((error) => {
            alertError(error);
        });
    };

    if (user && user.currentUser && !user.isAdmin) {
        return (<Progress/>);
    }


    const columns = [
        {
            name: 'id',
            options: {
                display: false,
            }
        },
        {
            'name': 'username',
            'label': 'User Name',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'email',
            'label': 'Email',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'role',
            'label': 'Role',
            options: {
                filter: true,
                sort: true,
                display: true,
            }
        },
        {
            'name': 'action',
            'label': 'Action',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Button variant="contained" disableElevation onClick={() => {
                                setFocussedItem(tableMeta.rowData);
                                setUpdateRoleDialogOpen(true);
                            }}>Set Role</Button>
                            <IconButton variant="contained" style={{marginLeft:'5px'}} color="secondary"
                                        disableElevation
                                        onClick={() => {
                                            setFocussedItem(tableMeta.rowData);
                                            setDeleteItemDialogOpen(true);
                                        }}>
                                <Delete/>
                            </IconButton>
                        </>
                    )
                }
            },
        },
    ];

    const options = {
        filterType: 'checkbox',
        filter: false,
        print: false,
        disableToolbarSelect: true,
        search: false,
        selectableRows: false,
        elevation: 0,
    };


    return(
        <Parent title='User Management'>
            <MUIDataTable
                title={"Inventory List"}
                data={tableData}
                columns={columns}
                options={options}
            />
            <Dialog open={updateRoleDialogOpen} onClose={handleUpdateUserRoleDialogClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="alert-dialog-title">Update Role of {focussedItem[1]}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Select a role from the below list to update.
                    </DialogContentText>
                    <FormControl style={{minWidth: 120}}>
                    <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={focussedItem[3]}
                    onChange={(e) => {
                        let newFocus = focussedItem;
                        newFocus[3] = e.target.value;
                        setFocussedItem(newFocus);
                    }}
                    >
                        <MenuItem value={'ADMIN'}>Admin</MenuItem>
                        <MenuItem value={'SALES'}>Sales</MenuItem>
                        <MenuItem value={'WAREHOUSE'}>Warehouse</MenuItem>
                        <MenuItem value={'NO_ACCESS'}>No Access</MenuItem>
                    </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateUserRoleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUserRoleUpdate} color="primary" autoFocus>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog open={deleteItemDialogOpen}
                                title={'Delete Item'}
                                contentText={'Are you sure you want to delete this user?'}
                                onConfirm={handleItemDelete}
                                onClose={handleDeleteItemDialogClose}/>
        </Parent>
    )
}
