import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles/index';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {ENTER_KEY_CODE} from "../../constants";
import {Clear} from "@material-ui/icons";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => (
    {
        bootstrapRoot: {
            padding: 0,
            'label + &': {
                marginTop: theme.spacing(1),
            },
        },
        bootstrapInput: {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            border: '1px solid #ced4da',
            padding: '5px 12px',
            marginTop: '11px',
            marginBottom: '11px',
            width: '100%',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
            fontSize: 12,
            height:'20px',
        },
    }
));

export default function CustomerSearch(props) {
    const classes = useStyles();
    const {onSearch, searchBtn, resetFullView} = props;
    const [searchState, setSearchState] = useState({});
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [address, setAddress] = useState('');
    const [contact, setContact] = useState('');

    const handleCustomerSearchChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;

        let newState = searchState;
        if (value === '' || value.length === 0) {
            delete newState[name];
        } else {
            newState[name] = value;
        }
        setFormValues(name, value);
        setSearchState(newState);
    };

    function setFormValues(name, value) {
        if (name === 'fname') {
            setFname(value);
        } else if (name === 'lname') {
            setLname(value);
        } else if (name === 'contact') {
            setContact(value);
        } else if (name === 'address') {
            setAddress(value);
        }
    }

    const handleReset = () => {
        setFname('');
        setLname('');
        setContact('');
        setAddress('');
        setSearchState({});
        if (resetFullView) {
            onSearch({});
        } else {
            onSearch({fname:'empty'});
        }
    };

    const handleOnkeyDown = (e) => {
        if (e.keyCode === ENTER_KEY_CODE) {
            onSearch(searchState);
        }
    };

    return (
        <div>
            <Grid container spacing={1} direction="row" border={2}>
                <Grid item xs={1}/>
                <Grid item xs={2}>
                    <TextField
                        name='fname'
                        id='standard-full-width'
                        label={'First Name'}
                        required
                        defaultValue={''}
                        onChange={handleCustomerSearchChange}
                        onKeyDown={handleOnkeyDown}
                        value={fname}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        name='lname'
                        id='standard-full-width'
                        label={'Last Name'}
                        required
                        onChange={handleCustomerSearchChange}
                        onKeyDown={handleOnkeyDown}
                        value={lname}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        name='contact'
                        id='standard-full-width'
                        label={'Contact Number'}
                        required
                        onChange={handleCustomerSearchChange}
                        onKeyDown={handleOnkeyDown}
                        value={contact}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        name='address'
                        id='standard-full-width'
                        label={'Address'}
                        required
                        onChange={handleCustomerSearchChange}
                        onKeyDown={handleOnkeyDown}
                        value={address}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    {searchBtn && <Button onClick={onSearch} color="primary">Search</Button>}
                    <Button onClick={handleReset} variant="outlined" disableElevation size="small"
                            startIcon={<Clear/>}
                            style={{marginTop: '19px'}}>
                        Reset
                    </Button>
                </Grid>
                <Grid item xs={1}/>
            </Grid>
        </div>
    );
}

CustomerSearch.propTypes = {
    onSearch: PropTypes.func,
    searchBtn: PropTypes.bool,
    resetFullView: PropTypes.bool
};

CustomerSearch.defaultProps = {
    onSearch: () => {
    },
    searchBtn: false,
    resetFullView: true
};
