import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import ResourceApi from "../../apis/Api";
import Search from '../common/CustomerSearch';
import _ from "lodash";
import PropTypes from "prop-types";
import {handleError} from "../../utils/MiscellaniousUtils";

export default function CustomerSelector(props) {

    const { orderId, onComplete } = props;
    const [customers, setCustomers] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [insertCustomerDialogOpen, setInsertCustomerDialogOpen] = useState(false);
    const api = new ResourceApi();

    useEffect(() => {
        retrieveAllCustomers();
    }, []);

    function retrieveAllCustomers() {
        api.getAllCustomers().then((response) => {
            let list = response.data;
            if (list) {
                setCustomers(list);
            }
            setTableData(createRows(list));
        }).catch((error) => {
            handleError(error);
        });
    }

    function createRows(list) {
        let rows = [];
        list.forEach((element) => {
            const rowData = [];
            rowData.push(element.id);
            rowData.push(element.fname);
            rowData.push(element.lname);
            rowData.push(element.cnumber);
            rowData.push(element.address);
            rows.push(rowData);
        });
        return rows;
    }

    const handleSearch = (searchState) => {
        const filteredItems = _.filter(customers, function (o) {
            return _.every(searchState, function (v, k) {
                return _.includes(o[k].toLowerCase(), v.toLowerCase());
            });
        });
        setTableData(createRows(filteredItems));
    };

    const columns = [
        {
            name: 'id',
            options: {
                display: false,
            }
        },
        {
            'name': 'fname',
            'label': 'First Name',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'lname',
            'label': 'Last Name',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'cnumber',
            'label': 'Contact',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'address',
            'label': 'Address',
            options: {
                filter: true,
                sort: true,
            }
        },
    ];

    const options = {
        filterType: 'checkbox',
        filter: false,
        print: false,
        disableToolbarSelect: true,
        download:false,
        search:false,
        viewColumns: false,
        elevation: 0,
    };

    return (
        <>
            <Search onSearch={handleSearch} searchBtn={false} resetFullView={true}/>
            <MUIDataTable
                data={tableData}
                columns={columns}
                options={options}
            />
        </>
    );
}

CustomerSelector.propTypes = {
    orderId: PropTypes.number,
    onComplete: PropTypes.func
};

CustomerSelector.defaultProps = {
    orderId: 0,
    onComplete: () => {
    },
};
