import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import MUIDataTable from "mui-datatables";
import ResourceApi from '../../apis/Api';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Search from './InventoryPOSearch';
import _ from 'lodash';
import CustomToolbar from '../custom/CustomToolbar';
import {Add} from "@material-ui/icons";
import PropTypes from "prop-types";
import {handleError} from "../../utils/MiscellaniousUtils";

export default function InventorySelector(props) {

    const { orderId, onComplete } = props;
    const [products, setProducts] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [qtyDialogOpen, setQtyDialogOpen] = useState(false);
    const [itemQty, setItemQty] = useState(null);
    const [invId, setInvId] = useState(null);
    const api = new ResourceApi();

    useEffect(() => {
        retrieveAllInventory();
    }, []);

    const tableDataWithRows = createRows(tableData);

    function retrieveAllInventory() {
        api.getAllInventoryDetails().then((response) => {
            console.log(response.data);
            let list = response.data;
            if (list) {
                setProducts(list);
            }
            setTableData(list);
        }).catch((error) => {
            handleError(error);
        });
    }

    function createRows(list) {
        let rows = [];
        list.forEach((element) => {
            console.log(element);
            const rowData = [];
            rowData.push(element.itemLocationId);
            rowData.push(element.poNumber);
            rowData.push(element.item);
            rowData.push(element.description);
            rowData.push(element.color);
            rowData.push(element.location);
            rowData.push(element.availableQty);
            rows.push(rowData);
        });
        return rows;
    }

    const handleQtyDialogOpen = () => {
        setQtyDialogOpen(true);
    };

    const handleQtyDialogClose = () => {
        setQtyDialogOpen(false);
    };

    const handleChange = (e) => {
        if (e.target.name === 'qty') {
            setItemQty(e.target.value);
        }
    };

    const handleSearch = (searchState) => {
        const filteredItems = _.filter(products, function (o) {
            return _.every(searchState, function (v, k) {
                return _.startsWith(o[k].toLowerCase(), v.toLowerCase());
            });
        });
        setTableData(filteredItems);
    };

    const handleAddItemToOrder = () => {
        api.addInventoryToOrder(invId, itemQty, orderId).then((response) => {
            handleQtyDialogClose();
            onComplete();
        }).catch((error) => {
            handleError(error);
        });
    };

    function renderCustomToolBar() {
        return (<>
            <CustomToolbar disabled={tableDataWithRows.length !== 1} buttonText={'Add Item'}
                           startIcon={<Add/>}
                           handleClick={() => {
                               console.log(tableDataWithRows[0]);
                               setInvId(tableDataWithRows[0][0]);
                               handleQtyDialogOpen();
                           }}
            />
        </>);

    }

    function getOrderQtyHelperText(itemQty, data) {
        if (data === null || data.length <= 0) {
            return "";
        }

        let availableQty = data[0][6]
        if (availableQty === null || availableQty === "") {
            return "";
        } else if (itemQty < 1) {
            return "Invalid order qty!";
        } else if (itemQty > availableQty) {
            return "Available qty is " + availableQty + "!";
        } else {
            return "";
        }
    }

    const columns = [
        {
            name: 'id',
            options: {
                display: false,
            }
        },
        {
            'name': 'po',
            'label': 'PO #',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'item',
            'label': 'Item',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'desc',
            'label': 'Item Description',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'color',
            'label': 'Color',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'location',
            'label': 'Location',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'a-stock',
            'label': 'Available Stock',
            options: {
                filter: false,
                sort: false,
            }
        },
    ];

    const options = {
        filterType: 'checkbox',
        filter: false,
        print: false,
        disableToolbarSelect: true,
        download:false,
        search:false,
        viewColumns: false,
        elevation: 0,
        customToolbar: () => {return (renderCustomToolBar());},
    };

    return (
        <>
            <Search onSearch={handleSearch} searchBtn={false} resetFullView={true} products={products} tableData={tableData}/>
            <MUIDataTable
                data={tableDataWithRows}
                columns={columns}
                options={options}
            />
            <Dialog
                open={qtyDialogOpen}
                onClose={handleQtyDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Set Quantity"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TextField
                            name='qty'
                            type='number'
                            id='standard-full-width'
                            label={'Order Quantity'}
                            required
                            onChange={handleChange}
                            fullWidth
                            helperText={getOrderQtyHelperText(itemQty, tableDataWithRows)}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleQtyDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddItemToOrder} color="primary"
                            disabled={tableDataWithRows.length > 0 && (itemQty < 1 || itemQty > tableDataWithRows[0][6])} // current qty
                            autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

InventorySelector.propTypes = {
    orderId: PropTypes.number,
    onComplete: PropTypes.func
};

InventorySelector.defaultProps = {
    orderId: 0,
    onComplete: () => {
    }
};
