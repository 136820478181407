import React, {useContext, useEffect, useState} from 'react';
import Parent from '../components/Parent';
import MUIDataTable from "mui-datatables";
import ResourceApi from '../apis/Api';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles/index';
import TextField from '@material-ui/core/TextField';
import ConfirmationDialog from '../components/common/ConfirmationDialog';
import Search from '../components/common/InventoryPOSearch';
import _ from 'lodash';
import {alertSuccess, handleError, handleFileSizeExceedsError} from "../utils/MiscellaniousUtils";
import {
    DISCONTINUE,
    DISCONTINUING,
    ENTER_KEY_CODE,
    IMPORT,
    IMPORTING,
    INTERNAL_SERVER_ERROR,
    MAX_FILE_SIZE,
    MAX_FILE_SIZE_STRING,
    SALES
} from "../constants";
import CustomToolbarImport from "../components/custom/CustomToolbarImport";
import IconButton from "@material-ui/core/IconButton";
import UserContext from '../context/UserContext';
import {Delete, Edit, Block} from "@material-ui/icons";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => (
    {
        bootstrapRoot: {
            padding: 0,
            'label + &': {
                marginTop: theme.spacing(1),
            },
        },
        bootstrapInput: {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            border: '1px solid #ced4da',
            padding: '5px 12px',
            marginTop: '11px',
            marginBottom: '11px',
            width: '100%',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
            fontSize: 12,
        },
    }
));

export default function InventoryPOPage(props) {

    const user = useContext(UserContext);
    const classes = useStyles();
    const [products, setProducts] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [focussedItem, setFocussedItem] = useState([]);
    const [deleteItemDialogOpen, setDeleteItemDialogOpen] = useState(false);
    const [discontinueItemDialogOpen, setDiscontinueItemDialogOpen] = useState(false);
    const [inventoryUpdateDialogOpen, setInventoryUpdateDialogOpen] = useState(false);
    const [editState, setEditState] = useState({
        inventoryId: null,
        poNumber: '',
        itemId: null,
        itemLocationId: null,
        item: '',
        description: '',
        color: '',
        location: '',
        availableQty: null,
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedDiscontinueFile, setSelectedDiscontinueFile] = useState(null);
    const[selectedFileError, setSelectedFileError] = useState('');
    const [selectedFileInputKey, setSelectedFileInputKey] = useState(Math.random().toString(36));
    const [importBtnText, setImportBtnText] = useState(IMPORT);
    const [isProcessing, setIsProcessing] = useState(false);
    const [mergeDiagnostics, setMergeDiagnostics] = useState('');

    useEffect(() => {
        retrieveAllInventory();
    },[]);

    function retrieveAllInventory() {
        const api = new ResourceApi();
        api.getAllInventoryDetails().then((response) => {
            let list = response.data;
            if (list) {
                setProducts(list);
            }
            setTableData(list);
        }).catch((error) => {
            handleError(error);
        });
    }

    function createRows(list) {
        let rows = [];
        list.forEach((element) => {
            const rowData = [];
            rowData.push(element.id);
            rowData.push(element.poNumber);
            rowData.push(element.itemId);
            rowData.push(element.itemLocationId);
            rowData.push(element.item);
            rowData.push(element.description);
            rowData.push(element.color);
            rowData.push(element.location);
            rowData.push(element.availableQty);
            rows.push(rowData);
        });
        return rows;
    }

    function mergeShouldRender() {
       return user && user.currentUser && user.isAdmin
    }

    function actionsShouldRender() {
        return user && user.currentUser && !(user.role === SALES.value)
    }

    const handleSearch = (searchState) => {
        const filteredItems = _.filter(products, function (o) {
            return _.every(searchState, function (v, k) {
                return _.startsWith(o[k].toLowerCase(), v.toLowerCase());
            });
        });
        setTableData(filteredItems);
    };

    const handleDeleteItemDialogClose = () => {
        setDeleteItemDialogOpen(false);
    };

    const handleDiscontinueItemDialogClose = () => {
        setDiscontinueItemDialogOpen(false);
    };

    const handleUpdateInventoryClose = () => {
        setInventoryUpdateDialogOpen(false);
    };

    const handleEditFormChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let newState = editState;
        newState[name] = value;
        setEditState(newState);
    };

    const handleInventoryUpdate = () => {
        console.log(editState)
        new ResourceApi().updateItemLocation(editState).then((response) => {
            handleUpdateInventoryClose();
            retrieveAllInventory();
        }).catch((error) => {
            handleError(error);
        });
    };

    const handleItemDelete = () => {
        const api = new ResourceApi();
        api.deleteInventoryLocation(focussedItem[3]).then((response) => {
            handleDeleteItemDialogClose();
            retrieveAllInventory();
        }).catch((error) => {
            handleError(error);
        });
    };

    const handleItemDiscontinue = () => {
        const api = new ResourceApi();
        api.discontinueInventoryLocation(focussedItem[3]).then((response) => {
            handleDiscontinueItemDialogClose();
            retrieveAllInventory();
        }).catch((error) => {
            handleError(error);
        });
    };

    const handleSelectInventoryMergeFile = (e) => {
        if (e.target.files[0] !== undefined) {
            if (e.target.files[0].size > MAX_FILE_SIZE) {
                handleFileSizeExceedsError(MAX_FILE_SIZE_STRING);
                setSelectedFile(null);
            } else {
                setSelectedFile(e.target.files[0]);
            }
        }
    };

    const handleSelectInventoryDiscontinueFile = (e) => {
        if (e.target.files[0] !== undefined) {
            if (e.target.files[0].size > MAX_FILE_SIZE) {
                handleFileSizeExceedsError(MAX_FILE_SIZE_STRING);
                setSelectedDiscontinueFile(null);
            } else {
                setSelectedDiscontinueFile(e.target.files[0]);
            }
        }
    };

    const handleMergeBulkInventory = () => {
        const data = new FormData();
        data.append('file', selectedFile);

        if (validateInputs()) {
            setImportBtnText(IMPORTING);
            setIsProcessing(true);

            const api = new ResourceApi();
            api.importInvStock(data)
                .then(res => {
                    setImportBtnText(IMPORT);
                    setIsProcessing(false);
                    alertSuccess(res.data);
                    clearFrom();
                    retrieveAllInventory();
                })
                .catch(error => {
                    setImportBtnText(IMPORT);
                    setIsProcessing(false);
                    let err = INTERNAL_SERVER_ERROR;
                    if (error.response) {
                        err = error.response.data;
                        if (typeof err !== "string") {
                            console.log(err);

                            let errMsg = "[" + err.error + "]<br/><b>" + err.message + "</b><br/><br/>";
                            if (err.trace) {
                                let ks = err.trace.split("\n");
                                errMsg += "\n" + ks[0];
                            }
                            err = errMsg;
                        }
                    }
                    setMergeDiagnostics(err);
                });
        }
    };

    const handleDiscontinueBulkInventory = () => {
        console.log('handleDiscontinueBulkInventory');
        const data = new FormData();
        data.append('file', selectedDiscontinueFile);

        if (validateInputsForDiscontinue()) {
            setImportBtnText(DISCONTINUING);
            setIsProcessing(true);

            const api = new ResourceApi();
            api.discontinueItemLocationsStock(data)
                .then(res => {
                    setImportBtnText(DISCONTINUE);
                    setIsProcessing(false);
                    alertSuccess(res.data);
                    clearFrom();
                    retrieveAllInventory();
                })
                .catch(error => {
                    setImportBtnText(DISCONTINUE);
                    setIsProcessing(false);
                    let err = INTERNAL_SERVER_ERROR;
                    if (error.response) {
                        err = error.response.data;
                        if (typeof err !== "string") {
                            console.log(err);

                            let errMsg = "[" + err.error + "]<br/><b>" + err.message + "</b><br/><br/>";
                            if (err.trace) {
                                let ks = err.trace.split("\n");
                                errMsg += "\n" + ks[0];
                            }
                            err = errMsg;
                        }
                    }
                    setMergeDiagnostics(err);
                });
        }
    };

    const validateInputs = () => {
        if (selectedFile == null) {
            setSelectedFileError('Pls select the file you want to import!');
            return false;
        } else {
            setSelectedFileError('');
        }
        return true;
    };

    const validateInputsForDiscontinue = () => {
        if (selectedDiscontinueFile == null) {
            setSelectedFileError('Pls select the file you want to import!');
            return false;
        } else {
            setSelectedFileError('');
        }
        return true;
    };

    const clearFrom = () => {
        setSelectedFile(null);
        setSelectedFileInputKey(Math.random().toString(36));
    };

    const columns = [
        {
            name: 'id',
            options: {
                display: false,
            }
        },
        {
            'name': 'po',
            'label': 'PO #',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'ItemId',
            options: {
                display: false,
            }
        },
        {
            name: 'itemLocationId',
            options: {
                display: false,
            }
        },
        {
            'name': 'item',
            'label': 'Item',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'desc',
            'label': 'Item Description',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'color',
            'label': 'Color',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'location',
            'label': 'Location',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            'name': 'a-stock',
            'label': 'Available Stock',
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({
                    align: "right"
                }),
            }
        },
        {
            'name': 'merge',
            'label': 'Merge',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (actionsShouldRender()) {
                        return (
                            <>
                                <TextField id="outlined-basic" label="Add Stocks..." variant="outlined" size="small"
                                           type="number" onKeyDown={(event) => {
                                    console.log("triggered");
                                    if (event.keyCode === ENTER_KEY_CODE) {
                                        let qty = parseInt(event.target.value);
                                        if (qty > 0) {
                                            const api = new ResourceApi();
                                            api.addStockToInventory(tableMeta.rowData[3], qty).then((response) => {
                                                retrieveAllInventory();
                                            }).catch((error) => {
                                                handleError(error);
                                            });
                                            event.target.value = "";
                                        } else {
                                            alert("Invalid stock qty!\nStock should be greater than 0");
                                        }
                                    }
                                }}/>
                            </>
                        )
                    }
                }
            }
        },
        {
            'name': 'actions',
            'label': 'Actions',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (actionsShouldRender()) {
                        return (
                            <>
                                <IconButton variant="contained" disableElevation onClick={() => {
                                    //setFocussedItem(tableMeta.rowData);
                                    setEditState({
                                        inventoryId: tableMeta.rowData[0],
                                        poNumber: tableMeta.rowData[1],
                                        itemId: tableMeta.rowData[2],
                                        itemLocationId: tableMeta.rowData[3],
                                        item: tableMeta.rowData[4],
                                        description: tableMeta.rowData[5],
                                        color: tableMeta.rowData[6],
                                        location: tableMeta.rowData[7],
                                        availableQty: tableMeta.rowData[8],
                                    });
                                    setInventoryUpdateDialogOpen(true);
                                }}>
                                    <Edit/>
                                </IconButton>
                                <IconButton variant="contained" style={{marginLeft: '5px'}} color="secondary"
                                            disableElevation
                                            onClick={() => {
                                                setFocussedItem(tableMeta.rowData);
                                                setDeleteItemDialogOpen(true);
                                            }}>
                                    <Delete/>
                                </IconButton>
                                <IconButton variant="contained" style={{marginLeft: '5px'}} color="secondary"
                                            disableElevation
                                            onClick={() => {
                                                setFocussedItem(tableMeta.rowData);
                                                setDiscontinueItemDialogOpen(true);
                                            }}>
                                    <Block/>
                                </IconButton>
                            </>
                        )
                    }
                }
            }
        },
    ];

    const options = {
        filterType: 'checkbox',
        filter: false,
        print: false,
        disableToolbarSelect: true,
        search: false,
        elevation: 0,
        selectableRows: false,
    };

    return (
        <Parent title='Inventory Look Up'>
            {mergeShouldRender() && (
                <>
                    <CustomToolbarImport onChangeFile={handleSelectInventoryMergeFile}
                                         handleClick={handleMergeBulkInventory}
                                         buttonText={'Merge'}/>
                    <CustomToolbarImport onChangeFile={handleSelectInventoryDiscontinueFile}
                                         handleClick={handleDiscontinueBulkInventory}
                                         buttonText={'Discontinue'}/>
                    <br/><br/><br/>
                    {mergeDiagnostics && mergeDiagnostics !== '' && (
                        <Alert severity="error" style={{marginBottom: 20}}>
                            <span dangerouslySetInnerHTML={{__html: mergeDiagnostics}}/>
                        </Alert>
                    )}
                </>
            )}
            <Search onSearch={handleSearch} searchBtn={false} resetFullView={true} products={products} tableData={tableData}/>
            <MUIDataTable
                title={"Inventory List"}
                data={createRows(tableData)}
                columns={columns}
                options={options}
            />
            <Dialog open={inventoryUpdateDialogOpen} onClose={handleUpdateInventoryClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="alert-dialog-title">{"Update Inventory Location Details"}</DialogTitle>
                <DialogContent>
                    <TextField
                        name='location'
                        id='standard-full-width'
                        label={'Location'}
                        required
                        defaultValue={editState.location}
                        onChange={handleEditFormChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name='availableQty'
                        id='standard-full-width'
                        label={'Available Stock'}
                        required
                        type={'number'}
                        defaultValue={editState.availableQty}
                        onChange={handleEditFormChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapInput,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateInventoryClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleInventoryUpdate} color="primary" autoFocus>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog open={deleteItemDialogOpen}
                                title={'Delete Item'}
                                contentText={'Are you sure you want to delete this item? You cannot revert this action'}
                                onConfirm={handleItemDelete}
                                onClose={handleDeleteItemDialogClose}/>
            <ConfirmationDialog open={discontinueItemDialogOpen}
                                title={'Discontinue Item'}
                                contentText={'Are you sure you want to discontinue this item? You cannot revert this action'}
                                onConfirm={handleItemDiscontinue}
                                onClose={handleDiscontinueItemDialogClose}/>
        </Parent>
    );
}
