import Spinner from "react-bootstrap/Spinner";
import React from "react";
import {FaFileExport, FaFileImport, FaFilter} from "react-icons/fa";
import {IMPORT_TASK, INTERNAL_SERVER_ERROR, NO_ACCESS_OPTION, SAVE} from "../constants";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {Delete, Save} from "@material-ui/icons";

const MySwal = withReactContent(Swal);

export function alertError(msg) {
    MySwal.fire("Something Went Wrong!", msg, "error");
}

export function alertSuccess(msg) {
    MySwal.fire("Good job!", msg, "success");
}

export function alertSuccessfullyUpdated(msg) {
    MySwal.fire("Sucessfully Updated", msg, "success");
}

export function showSpinner(btnText) {
    if (btnText === "Import") {
        return <span><FaFileImport/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export function showImportTaskSpinner(btnText) {
    if (btnText === IMPORT_TASK) {
        return <span><FaFileImport/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export function showFilterSpinner(btnText) {
    if (btnText === "Filter") {
        return <span><FaFilter/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export function showExportSpinner(btnText) {
    if (btnText === "Export") {
        return <span><FaFileExport/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export function showDeleteSpinner(btnText) {
    if (btnText === "Delete") {
        return <span><Delete/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export function showSaveSpinner(btnText) {
    if (btnText === SAVE) {
        return <span><Save/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export function showUploadSpinner(btnText) {
    if (btnText === "Upload") {
        return <span><FaFileExport/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export function handleErr(error) {
    let err = INTERNAL_SERVER_ERROR;
    if (error.response) {
        err = error.response.data;
    }
    alertError(err);
}

export function handleError(error) {
    let err = INTERNAL_SERVER_ERROR;
    if (error.response) {
        err = error.response.data;
        if (typeof err !== "string") {
            console.log(err);

            let errMsg = "[" + err.error + "]<br/><b>" + err.message + "</b><br/><br/>";
            if (err.trace) {
                let ks = err.trace.split("\n");
                errMsg += "\n" + ks[0];
            }
            err = errMsg;
        }
    }
    alertError(err);
}

export function handleFileSizeExceedsError(msg){
    MySwal.fire("File size Exceeds", "Please add a file less than " + msg + " size", "error");
}

export function handleNamedFileSizeExceedsError(name, msg){
    MySwal.fire("File named " + name + " size Exceeds", "Please add a file less than " + msg + " size", "error");
}

export function isEmptyOrUndefined(str) {
    return str === undefined || str === '';
}

export function getIdFromUrl() {
    let url = window.location.href;
    return url.substring(url.lastIndexOf('/') + 1);
}

export function getBaseUrl() {
    return window.location.protocol + '//' + window.location.host + '/';
}

export function setEmptyIfNull(value) {
    if (value !== null) {
        return value;
    } else {
        return "";
    }
}

export function getOptionUsingValue(options, value) {
    return options.find(obj => {
        return obj.value === setEmptyIfNull(value)
    })
}

export function getShowingResultsPhrase(count, size) {
    return `[ Showing latest ${count} of ${size} results ]`;
}

export function createTypeOption(type) {
    if (type === null || type === NO_ACCESS_OPTION.value) {
        return NO_ACCESS_OPTION;
    } else {
        return {value: type, label: type};
    }
}