import React, {Component} from 'react';
import {Route, Redirect, Switch} from 'react-router';
import AuthService from './services/AuthService';
import {ADMIN, NO_ACCESS} from "../constants";
import POListingPage from "../pages/POListingPage";
import PODetailsPage from "../pages/PODetailsPage";
import CabinetPullSheet from "../pages/CabinetPullSheetPage";
import InventoryPOPage from "../pages/InventoryPOPage";
import InventoryPage from "../pages/InventoryPage";
import UsersPage from '../pages/UsersPage';
import SalesTeamInventoryPage from '../pages/SalesTeamInventoryPage';
import Qs from 'qs';
import {alertError} from "../utils/MiscellaniousUtils";
import {UserProvider} from '../context/UserContext';

export default class SecuredRouter extends Component {

    constructor(props) {
        super(props);
        this.handleSessionInvalid = this.handleSessionInvalid.bind(this);
        window.handleSessionInvalid = this.handleSessionInvalid;

        this.state = {
            currentUser: null,
            isAdmin: false,
            role: null,
            redirectUrl: undefined,
        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();
        if (user) {
            const role = AuthService.getCurrentUserRole();
            if (role) {
                this.setState({
                    currentUser: user,
                    isAdmin: role === ADMIN.value,
                    role: role
                });
            } else {
                alertError("Unable to fetch User Role! Please contact Admin");
            }
        }
    }

    handleSessionInvalid() {
        this.forceUpdate();
    }

    render() {
        const user = AuthService.getCurrentUser();
        const role = AuthService.getCurrentUserRole();
        if (!user || role === NO_ACCESS.value) {
            let referrer = this.props.location.pathname;
            const arr = referrer.split('');
            if (arr[arr.length - 1] !== '/') {
                referrer += '/';
            }
            const params = Qs.stringify({referrer});
            return (
                <Redirect to={{pathname: '/login', search: params}}/>
            );
        }
        return (
            <UserProvider value={this.state}>
                <Switch>
                    <Route exact path='/'>
                        <Redirect to="/inventory-lookup"/>
                    </Route>
                    <Route exact path='/inventory-lookup' component={InventoryPOPage}/>
                    <Route exact path='/inventory' component={InventoryPage}/>
                    <Route exact path='/po/details/:id' component={PODetailsPage}/>
                    <Route exact path='/po-list' component={POListingPage}/>
                    <Route exact path='/cabinet-pull-sheet' component={CabinetPullSheet}/>
                    <Route exact path='/users' component={UsersPage}/>
                    <Route exact path='/containers' component={SalesTeamInventoryPage}/>
                </Switch>
            </UserProvider>
        );
    }
}
