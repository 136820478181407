import React, { useState, useEffect } from 'react';
import Parent from '../components/Parent';
import MUIDataTable from "mui-datatables";
import Api from '../apis/Api';

export default function CabinetPullSheetPage(props) {

    const [products, setProducts] = useState('');
    useEffect(() => {

    },[]);


    const columns = ["PO #", "Item", "Item Description", "Color", "Location", "Total Units"];
    const data = [
        ["Joe James", "Test Corp", "Yonkers", "NY", "Yonkers", "NY"],
        ["John Walsh", "Test Corp", "Hartford", "CT", "Yonkers", "NY"],
        ["Bob Herm", "Test Corp", "Tampa", "FL", "Yonkers", "NY"],
        ["James Houston", "Test Corp", "Dallas", "TX", "Yonkers", "NY"],
    ];

    const options = {
        filterType: 'checkbox',
        filter: false,
        print: true,
        viewColumns: false,
        responsive: 'standard',
        selectableRows: false,
    };


    return (
        <Parent title='Cabinet Pull Sheet'>
            <MUIDataTable
                data={data}
                columns={columns}
                options={options}
            />
        </Parent>
    );
}
