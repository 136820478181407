import axios from 'axios';
import {AuthorizationHeader} from "../user/services/AuthHeader";
import {HOST_NAME} from "../constants";

export default class API {

    getHTTPClient(isLogin) {
        let httpClient = axios.create({
            baseURL: HOST_NAME,
            headers: AuthorizationHeader()
        });

        httpClient.defaults.headers.post['Content-Type'] = 'application/json';

        httpClient.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response) {
                if (isLogin === undefined) {
                    if (error.response.status === 403) {
                        localStorage.removeItem('user');
                        window.location.href = "/";
                    }
                }
            }
            return Promise.reject(error);
        });

        return httpClient;
    }

    createEmptyOrder(customerId) {
        return this.getHTTPClient().put(`/pull-order?customer-id=${customerId}`);
    }

    getAllPullOrders() {
        return this.getHTTPClient().get('/pull-order/all');
    }

    deletePullOrder(id) {
        return this.getHTTPClient().delete(`/pull-order?id=${id}`);
    }

    // Inventory

    getAllInventory() {
        return this.getHTTPClient().get('/inventory/inventory');
    }

    getAllInventoryDetails() {
        return this.getHTTPClient().get('/inventory/all');
    }

    insertInventoryItem(inventory) {
        return this.getHTTPClient().put('/inventory', inventory);
    }

    insertPODetail(poDetail) {
        console.log(poDetail)
        return this.getHTTPClient().put('/inventory/po-detail', poDetail);
    }

    deleteInventoryItem(id) {
        return this.getHTTPClient().delete(`/inventory?id=${id}`);
    }

    deleteInventoryLocation(id) {
        return this.getHTTPClient().delete(`/inventory/location?id=${id}`);
    }

    discontinueInventoryLocation(id) {
        return this.getHTTPClient().post(`/item-locations/discontinue?id=${id}`);
    }

    getPullOrderInventory(id) {
        return this.getHTTPClient().get(`/pull-order/inventory?id=${id}`);
    }

    addInventoryToOrder(itemLocationId, qty, poId) {
        return this.getHTTPClient().put(`/pull-order/inventory?poId=${poId}&itemLocationId=${itemLocationId}&qty=${qty}`);
    }

    deleteInventoryFromOrder (id) {
        return this.getHTTPClient().delete(`/pull-order/inventory?id=${id}`);
    }

    setOrderAsPulled (id) {
        return this.getHTTPClient().post(`/pull-order/update-state/pulled?id=${id}`)
    }

    setOrderAsReverted (id) {
        return this.getHTTPClient().post(`/pull-order/update-state/revert?id=${id}`)
    }

    getPullOrderDetails(id) {
        return this.getHTTPClient().get(`/pull-order?id=${id}`);
    }

    addStockToInventory(id, qty) {
        return this.getHTTPClient().post(`/inventory/add-stock?id=${id}&qty=${qty}`);
    }

    updateInventoryItem(inventory) {
        return this.getHTTPClient().post('/inventory', inventory);
    }

    importInvStock(data) {
        return this.getHTTPClient().post('/inventory/import-stock', data)
    }

    discontinueItemLocationsStock(data) {
        return this.getHTTPClient().post('/item-locations/discontinue-stock', data)
    }

    discontinueInvStock(data) {
        return this.getHTTPClient().post('/inventory/discontinue-stock', data)
    }

    // Item locations

    updateItemLocation(inventory) {
        return this.getHTTPClient().post('/item-locations', inventory);
    }

    // Customer

    createCustomer(customer) {
        return this.getHTTPClient().put('/customer', customer);
    }

    getCustomer(id) {
        return this.getHTTPClient().get(`/customer?id=${id}`);
    }

    getAllCustomers() {
        return this.getHTTPClient().get('customer/all');
    }

    getReorderItems() {
        return this.getHTTPClient().get('/reorder-items');
    }
}
