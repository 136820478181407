import React, { useState } from 'react';
import { MuiThemeProvider } from 'material-ui/styles';
import defaultTheme from '../utils/Theme'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types';
import Header from '../components/common/Header';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    titleSection: {
        paddingBottom: '3%',
        marginTop:'30px',
        paddingTop:'20px'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    root: {
        display: 'flex',
    },
}));

export default function Parent(props) {

    const {children, theme, title} = props;
    const classes = useStyles();
    return (
        <MuiThemeProvider muiTheme={theme}>
            <Box className='page-container'>
                <Header hasDrawer={true}/>
                <Box className='content-wrap' style={{marginLeft:'240px'}}>
                    <Typography variant='h4' className={classes.titleSection}>
                        {title}
                    </Typography>
                    {children}
                </Box>
            </Box>
        </MuiThemeProvider>
    );
}

Parent.propTypes = {
    data: PropTypes.element,
    theme: PropTypes.shape({}),
    error: PropTypes.object,
    title: PropTypes.string,
};

Parent.defaultProps = {
    data: <span/>,
    theme: defaultTheme,
    error: null,
    title: 'Title',
};

