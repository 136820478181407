import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const defaultToolbarStyles = {
    iconButton: {
    },
};

class CustomToolbar extends React.Component {

    handleClick = () => {
        if (this.props.selectedRows) {
            this.props.handleClick(this.props.selectedRows);
        } else {
            this.props.handleClick();
        }
    };

    render() {
        const { classes, buttonText, startIcon } = this.props;

        return (
            <React.Fragment>
                <Tooltip title={"custom icon"}>
                    <Button variant="contained" onClick={this.handleClick}
                            disabled={this.props.disabled} startIcon={startIcon} style={{marginLeft: 10}} disableElevation>
                        {buttonText}
                    </Button>
                </Tooltip>
            </React.Fragment>
        );
    }

}

CustomToolbar.propTypes = {
    disabled: PropTypes.bool,
};

CustomToolbar.defaultProps = {
    disabled: false,
};

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);
